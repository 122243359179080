<template>
  <div>
    <template v-if="isMobile()">
      <div class="error--text text-center">
        Chỉ cho phép kiểm hàng lẻ trên máy tính
      </div>
    </template>
    <template v-else>
      <v-row dense>
        <v-col cols="2">
          <select-warehouse-model
            v-model="id_warehouse"
            :label="$t('labels.warehouse')"
            dense
            outlined
            clearable
            hide-details
            @change="onWarehouseUpdate"
          ></select-warehouse-model>
        </v-col>
        <v-col cols="2">
          <select-customer-by-warehouse
            ref="scbwRef"
            v-model="id_customer"
            :id-warehouse="id_warehouse"
            :label="$t('labels.customer')"
            dense
            outlined
            clearable
            hide-details
            @change="onCustomerChange"
          ></select-customer-by-warehouse>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="stamp_size"
            :items="sizeStampOptions"
            :label="$t('labels.stamp_size')"
            dense
            outlined
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <input-qr-scan-model
            v-model="code"
            ref="codeRef"
            :label="placeholder"
            @keyupEnter="inputScan"
            :auto-close="false"
          ></input-qr-scan-model>
        </v-col>
      </v-row>

      <div class="mb-5">
        <div>
          <div class="d-flex align-center justify-space-between mb-3">
            <div
              :class="{ 'error--text text-decoration-underline': step === 2 }"
            >
              <span class="font-weight-medium">{{ $t("labels.d_size") }}:</span>
              {{ d_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 3 }"
            >
              <span class="font-weight-medium">{{ $t("labels.r_size") }}:</span>
              {{ r_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 4 }"
            >
              <span class="font-weight-medium">{{ $t("labels.c_size") }}:</span>
              {{ c_size }}
            </div>
            <div
              :class="{ 'error--text text-decoration-underline': step === 5 }"
            >
              <span class="font-weight-medium"
                >{{ $t("labels.weight_1") }}:</span
              >
              {{ weight }}
            </div>
          </div>
          <div
            class="mb-3"
            :class="{ 'error--text text-decoration-underline': step === 1 }"
          >
            <span class="font-weight-medium">{{ $t("labels.barcode") }}:</span>
            {{ barcode }}
            <span
              class="font-italic primary--text"
              style="font-size: 12px"
              v-if="quantity > 0"
            >
              ({{ $t("labels.scanned") }}: {{ quantity }})
            </span>
          </div>
          <div
            class="mb-3"
            :class="{ 'error--text text-decoration-underline': step === 6 }"
          >
            <span class="font-weight-medium"
              >{{ $t("labels.basket_code") }}:</span
            >
            {{ basket_code }}
          </div>
          <v-row>
            <v-col cols="3" v-if="goods.url_images">
              <ImageViewer :url="goods.url_images" height="106px" />
            </v-col>
            <v-col :cols="goods.url_images ? 9 : 12">
              <div class="mb-3">
                <span class="font-weight-medium">{{ $t("labels.name") }}:</span>
                {{ goods.goods_name || "" }}
              </div>
              <div class="mb-3">
                <span class="font-weight-medium"
                  >{{ $t("labels.goods_description") }}:</span
                >
                {{ goods.goods_description || "" }}
              </div>
              <div class="mb-3">
                <span class="font-weight-medium">{{ $t("labels.size") }}:</span>
                {{ goods.goods_size || "" }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-alert
        v-if="item.id_goods_receipt_history"
        text
        dense
        color="warning"
        border="left"
        style="font-size: 14px"
        class="my-3 py-2"
      >
        <div class="d-flex align-center justify-space-between">
          <div>
            {{ $t("labels.sku") }}: {{ item.sku }} - {{ $t("labels.barcode") }}:
            {{ item.customer_goods_barcode }} - {{ $t("labels.stamp") }}:
            {{ item.sku }}@{{ item.uid }}
          </div>
          <div class="primary--text cursor-pointer" @click="printStamp()">
            <v-icon class="primary--text">mdi-printer</v-icon>
            {{ $t("labels.re_print") }}
          </div>
        </div>
      </v-alert>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
import { downloadExcelFile, isMobile } from "@/libs/helpers";

export default {
  name: "One",
  components: {},
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    d_size: null,
    r_size: null,
    c_size: null,
    stamp_size: null,
    weight: null,
    basket_code: null,
    id_warehouse: null,
    id_customer: null,
    barcode: null,
    quantity: 0,
    goods: {},
    item: {},
    step: 1,
    sizeStampOptions: [
      {
        text: "30x15",
        value: "30x15",
      },
      {
        text: "32x18",
        value: "32x18",
      },
      {
        text: "50x20",
        value: "50x20",
      },
      {
        text: "50x40",
        value: "50x40",
      },
    ],
  }),
  computed: {
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t("labels.sub_barcode");
        case 2:
          return this.$t("labels.d_size");
        case 3:
          return this.$t("labels.r_size");
        case 4:
          return this.$t("labels.c_size");
        case 5:
          return this.$t("labels.weight_1");
        case 6:
          return this.$t("labels.basket_or_barcode");
        default:
          return "--- ERROR ---";
      }
    },
  },
  mounted() {},
  methods: {
    isMobile,
    downloadExcelFile,
    onWarehouseUpdate() {
      if (!this.id_warehouse) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.scbwRef.focusInput();
      });
    },
    onCustomerChange() {
      if (!this.id_customer) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput();
      });
    },

    async inputScan() {
      if (!this.code) {
        return false;
      }
      //
      switch (this.step) {
        case 1:
          await this.scanSubBarcode();
          break;
        case 2:
          await this.setDSize();
          break;
        case 3:
          await this.setRSize();
          break;
        case 4:
          await this.setCSize();
          break;
        case 5:
          await this.setWeight();
          break;
        case 6:
          if (this.code.toUpperCase() === this.barcode.toUpperCase()) {
            await this.scanSubBarcodeAgain();
          } else {
            await this.scanBasket();
          }
          break;
        default:
          this.$vToastify.error(" --- ERROR --- ");
          document.getElementById("error_sound_player").play();
          break;
      }
    },

    async scanSubBarcodeAgain() {
      if (this.barcode && this.quantity > 0) {
        this.quantity++;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } else {
        this.$vToastify.warning(this.$t("messages.wrong_wrong"));
        document.getElementById("error_sound_player").play();
      }
    },

    async scanSubBarcode() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/get-goods-for-one",
          {
            code: this.code,
            id_warehouse: this.id_warehouse,
            id_customer: this.id_customer,
          }
        );
        this.goods = { ...data };
        this.d_size = this.goods.d_size;
        this.r_size = this.goods.r_size;
        this.c_size = this.goods.c_size;
        if (!this.stamp_size) {
          this.stamp_size = this.goods.stamp_size;
        }
        this.weight = this.goods.weight;
        this.barcode = this.goods.customer_goods_barcode;
        this.quantity = 1;
        if (this.weight) {
          this.step = 6;
        } else {
          this.step = 2;
        }
        document.getElementById("success_sound_player").play();
        this.code = null;
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
        this.code = null;
        this.isLoading = false;
      }
    },

    async scanBasket() {
      if (!this.code) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/create-history-one",
          {
            id_warehouse: this.id_warehouse,
            id_customer: this.id_customer,
            id: this.goods.id,
            code: this.code,
            d_size: this.d_size,
            r_size: this.r_size,
            c_size: this.c_size,
            stamp_size: this.stamp_size,
            weight: this.weight,
            quantity: this.quantity,
          }
        );
        this.item = { ...data };
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.printStamp();
      } catch (e) {
        console.error(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    async printStamp() {
      const stampName = `${this.stamp_size}_${this.item.sku}_${this.item.customer_goods_barcode}.pdf`;
      this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-stamp`,
        {
          id_goods: this.item.id,
          id_goods_receipt_history: this.item.id_goods_receipt_history,
          stamp_size: this.stamp_size,
          client_time: moment().format("HH:mm DD/MM/YYYY"),
        },
        stampName
      );
    },
  },
};
</script>

<style scoped></style>
